<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <full-calendar
        ref="fullCalendar"
        :options="calendarOptions"
      >
        <template v-slot:eventContent="arg">
          <i v-if="dateDayFormatter(arg.event.start) === dateDayFormatter(arg.event.end)">
            {{ timeFormatter(arg.event.start) }} to {{ timeFormatter(arg.event.end) }}
          </i>
          <i v-else>
            {{ dateTimeLongFormatter(arg.event.start) }} to {{ dateTimeLongFormatter(arg.event.end) }}
          </i> -
          <b>{{ arg.event.title }}</b>
        </template>
      </full-calendar>
    </b-card>

    <b-modal
      id="modal-booking-form"
      size="lg"
      scrollable
      no-close-on-backdrop
      title="New Booking"
      @ok="onConfirmAction"
    >
      <ValidationObserver
        ref="form"
      >
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="venue"
            vid="venue"
            rules="required"
          >
            <b-form-group>
              <label for="venue">Venue</label>
              <b-form-select
                id="venue"
                v-model="booking.venue"
                :options="venues"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="(state.busy || booking.details.length > 0)"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Venue --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="date from"
            vid="date_from"
            rules="required"
          >
            <b-form-group>
              <label for="date_from">Date From</label>
              <b-input
                id="date_from"
                v-model="booking.date_from"
                type="datetime-local"
                :state="errors.length > 0 ? false : null"
                placeholder="enter date from"
                autocomplete="off"
                :min="minDateFrom"
                :disabled="(state.busy || booking.details.length > 0 || booking.venue === null)"
                @change="onDateFromChange"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="date to"
            vid="date_to"
            rules="required"
          >
            <b-form-group>
              <label for="date_to">Date To</label>
              <b-input
                id="date_to"
                v-model="booking.date_to"
                type="datetime-local"
                :state="errors.length > 0 ? false : null"
                placeholder="enter date to"
                autocomplete="off"
                :min="minDateTo"
                :disabled="(state.busy || booking.details.length > 0 || booking.venue === null)"
                @change="getItemList"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="purpose"
            vid="purpose"
            rules="required|max:500"
          >
            <b-form-group>
              <label for="purpose">Purpose</label>
              <b-textarea
                id="purpose"
                v-model="booking.purpose"
                :state="errors.length > 0 ? false : null"
                placeholder="enter purpose"
                autocomplete="off"
                rows="6"
                max-rows="6"
                :disabled="(state.busy || booking.venue === null)"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <div>
            <ValidationProvider
              #default="{ errors }"
              name="item"
              vid="item"
              rules=""
            >
              <b-form-group>
                <label for="item">Available Item(s)</label>
                <v-select
                  id="item"
                  v-model="selectedItem"
                  :options="items"
                  label="name"
                  :state="errors.length > 0 ? false : null"
                  :disabled="(state.busy || booking.venue === null)"
                >
                  <template #first>
                    <v-select-option
                      :value="null"
                      disabled
                    >
                      -- Please Select Item --
                    </v-select-option>
                  </template>
                </v-select>
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
            <b-button
              :disabled="(state.busy || addItemDisabled)"
              @click="onAddItem"
            >
              Add Item
            </b-button>
            <hr>
            <b-table
              hover
              responsive
              class="mt-2"
              :items="booking.details"
              :fields="tableTwoSettings.fields"
              show-empty
            >
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell()="data">
                <div class="text-nowrap">
                  {{ data.value }}
                </div>
              </template>

              <template #cell(actions)="row">
                <b-button
                  size="sm"
                  @click="onRemoveItem(row.item)"
                >
                  remove
                </b-button>
              </template>
            </b-table>

          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="(state.busy || booking.details.length < 1)"
          @click="ok()"
        >
          Submit
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { AxiosError, UserBookingService, SharedCalendarService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'

export default {
  name: 'UserBookingCalendar',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Booking Calendar'
    }
  },

  mixins: [formatter],

  data () {
    return {
      date_now: this.dateTimeLocalFormatter(Date.now()),
      state: {
        busy: false
      },
      selectedItem: null,
      items: [],
      venues: [],
      booking: {
        venue: null,
        purpose: '',
        date_from: this.dateTimeLocalFormatter(Date.now()),
        date_to: this.dateTimeLocalFormatter(Date.now()),
        details: []
      },
      calendarStart: '',
      calendarEnd: '',
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,timeGridDay,listMonth'
        },
        initialView: 'dayGridMonth',
        initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
        editable: false,
        selectable: true,
        selectMirror: false,
        dayMaxEvents: true,
        weekends: true,
        events: [],
        dateClick: this.handleDateSelect,
        datesSet: this.handleMonthChange
      },
      tableTwoSettings: {
        fields: [
          'index',
          { key: 'item_name' },
          'actions'
        ]
      }
    }
  },

  computed: {
    addItemDisabled () {
      const isOk = !!(this.selectedItem?.id !== null && this.booking.date_from < this.booking.date_to)
      return !isOk
    },

    minDateFrom () {
      return this.dateTimeLocalFormatter(this.date_now)
    },

    minDateTo () {
      return this.dateTimeLocalFormatter(this.booking.date_from)
    }

  },

  mounted () {
    core.index()
    this.getVenueList()
  },

  methods: {
    async getVenueList () {
      await SharedListService.getVenueList().then(({ data }) => {
        data.forEach(item => {
          this.venues.push({
            value: item.id,
            text: item.name,
            label: item.name
          })
        })
      })
    },

    async getItemList () {
      this.selectedItem = null
      if (this.booking.date_from > this.booking.date_to) {
        this.$refs.form.setErrors({
          date_to: ['The date to must be a date after date from']
        })
      } else {
        this.state.busy = true
        await SharedListService.getItemList(`date_from=${this.booking.date_from}&date_to=${this.booking.date_to}`).then(({ data }) => {
          this.items = data
          this.state.busy = false
        }).catch(() => {
          this.state.busy = false
        })
      }
    },

    onDateFromChange () {
      if (this.booking.date_from < this.dateTimeLocalFormatter(Date.now())) {
        this.$refs.form.setErrors({
          date_from: ['The date from must be a date after or equal to now']
        })
      } else {
        if (this.booking.date_from >= this.booking.date_to) {
          this.booking.date_to = null
        } else {
          this.getItemList()
        }
      }
    },

    async getCalendarEventList () {
      await SharedCalendarService.getCalendarEventList(`date_from=${this.calendarStart}&date_to=${this.calendarEnd}`).then(({ data }) => {
        const events = data.map(({ name, item, date_from, date_to }) => {
          return {
            title: ` ${item}`,
            start: this.dateTimeLocalFormatter(date_from),
            end: this.dateTimeLocalFormatter(date_to)
          }
        })
        this.onSetEvents(events)
      })
    },

    onShowAddForm () {
      this.booking.venue = null
      this.booking.purpose = ''
      this.booking.date_from = this.dateTimeLocalFormatter(Date.now())
      this.booking.date_to = this.dateTimeLocalFormatter(Date.now())
      this.booking.details = []
      this.selectedItem = null
      this.$bvModal.show('modal-booking-form')
    },

    onAddItem () {
      if (this.selectedItem.id === null) {
        this.$bvModal.msgBoxOk('Oops! You have selected invalid item', {
          title: 'Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          centered: true
        })
      } else if (this.booking.date_from >= this.booking.date_to) {
        this.$bvModal.msgBoxOk('Oops! Invalid booking date.', {
          title: 'Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          centered: true
        })
      } else {
        const isExisting = this.booking.details?.filter(
          el => el.item_id === this.selectedItem.id
        )

        if (isExisting.length) {
          this.$bvModal.msgBoxOk(`Item ${this.selectedItem.name} already exists in your item list.`, {
            title: 'Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        } else {
          this.booking.details.push({
            item_id: this.selectedItem.id,
            item_name: this.selectedItem.name
          })
          this.selectedItem = null
        }
      }
    },

    onRemoveItem (item) {
      const oldItem = this.booking.details

      const newItem = oldItem.filter(el => {
        return el.item_name !== item.item_name
      })

      this.booking.details = newItem
    },

    onSetEvents (data) {
      this.calendarOptions.events = data
    },

    async handleDateSelect (data) {
      if (this.dateFormatter(data.date) < this.dateFormatter(Date.now())) {
        this.$bvModal.msgBoxOk('The date from must be a date after or equal to now', {
          title: 'Warning',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          centered: true
        })
      } else {
        this.booking.venue = null
        this.booking.purpose = ''
        this.booking.date_from = this.dateTimeLocalFormatter(data.date)
        this.booking.date_to = this.dateTimeLocalFormatter(data.date)
        this.booking.details = []
        this.items = []
        this.selectedItem = null
        this.$bvModal.show('modal-booking-form')
      }
    },

    async handleMonthChange (data) {
      if (this.calendarStart !== this.dateFormatter(data.start)) {
        this.calendarStart = this.dateFormatter(data.start)
        this.calendarEnd = this.dateFormatter(data.end)
        await this.getCalendarEventList()
      }
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.booking.details.length < 1) {
        return
      }

      await this.$refs.form.validate().then(async success => {
        if (success) {
          const title = 'Do you really want to submit booking?'
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise((resolve, reject) => {
        this.state.busy = true
        UserBookingService.post(this.booking).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-booking-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.getCalendarEventList()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
